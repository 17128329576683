import React from 'react';

const IconMoon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.82849 1.62131C5.95892 1.75174 6.00694 1.94338 5.95344 2.1199C5.82125 2.55602 5.75 3.01919 5.75 3.49992C5.75 6.12327 7.87665 8.24992 10.5 8.24992C10.9807 8.24992 11.4439 8.17866 11.88 8.04647C12.0566 7.99297 12.2482 8.041 12.3786 8.17142C12.509 8.30185 12.5571 8.49349 12.5036 8.67001C11.7881 11.0307 9.59547 12.7499 7 12.7499C3.82436 12.7499 1.25 10.1756 1.25 6.99993C1.25 4.40446 2.96926 2.21186 5.32991 1.49636C5.50643 1.44286 5.69806 1.49089 5.82849 1.62131ZM4.79314 2.79246C3.28086 3.58733 2.25 5.17372 2.25 6.99993C2.25 9.62328 4.37665 11.7499 7 11.7499C8.82621 11.7499 10.4126 10.7191 11.2075 9.20678C10.9755 9.23526 10.7394 9.24992 10.5 9.24992C7.32436 9.24992 4.75 6.67556 4.75 3.49992C4.75 3.26054 4.76466 3.02442 4.79314 2.79246Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconMoon;
