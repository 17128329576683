import React from 'react';
import './ButtonBrand.scss';
import { Button } from 'antd';
import Icon from '@ant-design/icons';
import IconChainstack from '../Icons/IconChainstack';

const ButtonBrand = ({ label, link }) => {
  return (
    <Button
      type="primary"
      href={link}
      target="_blank"
      icon={<Icon component={IconChainstack} />}
    >
      {label}
    </Button>
    // <button className="button_brand">
    //   <svg
    //     width="18"
    //     height="18"
    //     viewBox="0 0 18 18"
    //     fill="currentColor"
    //     xmlns="http://www.w3.org/2000/svg"
    //   >
    //     <path d="M13.0826 12.6553H4.71739C4.42594 12.6559 4.13723 12.5994 3.86783 12.4891C3.59843 12.3788 3.35362 12.2169 3.14745 12.0126L1.43635 10.3156C1.02427 9.90356 0.793547 9.3464 0.794682 8.76606V4.35922C0.794097 4.07018 0.851042 3.78386 0.962253 3.51668C1.07346 3.2495 1.23676 3.00672 1.44276 2.80225L3.15387 1.10527C3.56974 0.696532 4.13189 0.467733 4.71739 0.468907H13.0826V2.90831H5.46493C4.87884 2.90887 4.31691 3.14002 3.90247 3.55103C3.48804 3.96204 3.25496 4.51933 3.2544 5.10059V8.02363C3.2544 8.60526 3.48722 9.16309 3.90172 9.57456C4.31621 9.98603 4.87846 10.2175 5.46493 10.218H13.0826V12.6553Z" />
    //     <path d="M5.71423 5.3479H14.0805C14.6649 5.34734 15.2258 5.57611 15.6408 5.98427L17.3519 7.68124C17.7669 8.09277 18 8.65092 18 9.23291V13.6397C18 14.2217 17.7669 14.7799 17.3519 15.1914L15.6408 16.8884C15.2258 17.2999 14.663 17.5311 14.0762 17.5311H5.71423V15.0917H13.3319C13.9187 15.0917 14.4815 14.8605 14.8965 14.449C15.3114 14.0375 15.5445 13.4793 15.5445 12.8973V9.97534C15.5445 9.39335 15.3114 8.8352 14.8965 8.42367C14.4815 8.01214 13.9187 7.78094 13.3319 7.78094H5.71423V5.3479Z" />
    //   </svg>
    //   <div className="button_brand_label">Sign in with Chainstack</div>
    // </button>
  );
};

export default ButtonBrand;
